// axios
import axios from 'axios'
import Vue from 'vue'
import { Message } from '@prettyy/ui'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  defaultTips: false, // 状态码非200、400、401时默认不提示错误
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => {
    if ([401, 400].includes(response.data.status)) {
      localStorage.removeItem('accessToken')

      window.location.href = '/login'

      return response
    }

    if (response.data.status !== 200 && response.config.defaultTips) {
      Message.error(response.data.message)
    }

    return response
  },
  error => {
    const { response } = error

    if (response && response.data) {
      Message.error(response.data.message)
    }
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
