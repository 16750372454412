<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import { Cascader } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

export default defineComponent({
  components: {
    Cascader,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Array, Number],
      default: () => null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    separator: {
      type: String,
      default: ',',
    },
    showAllLevels: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
  },
  setup(props, { emit }) {
    const calMarginTop = ref(0)
    const getLastNode = nodes => nodes[nodes.length - 1]

    const findAllParents = (tree, childId, parents = []) => {
      if (childId && tree[props.itemValue] === childId) {
        return parents
      }

      if (!tree.children) {
        return undefined
      }

      // 否则，对每个子节点递归查找
      for (let i = 0; i < tree.children.length; i += 1) {
        const foundParents = findAllParents(tree.children[i], childId, parents.concat(tree))
        if (foundParents) {
          return foundParents
        }
      }

      // 如果没有找到，返回undefined
      return undefined
    }

    const setElCascaderMarginTop = () => {
      const body = document.querySelector('body')
      calMarginTop.value = body.scrollTop
      body.scrollTop = 0
    }

    setElCascaderMarginTop()

    // 获取所有节点的value
    const findAllNodesValue = (items, value) => {
      const nodes = []
      const parents = findAllParents({ children: items, [props.itemValue]: 0 }, value) || []

      parents.forEach((item, index) => {
        if (index > 0) {
          nodes.push(item[props.itemValue])
        }
      })
      nodes.push(value)

      return nodes
    }

    const findAllNodes = (items, value) => {
      const nodes = []
      items.forEach(item => {
        if (item[props.itemValue] === value) {
          nodes.push(item)

          return nodes
        }
        if (item.children && item.children.length > 0) {
          const res = findAllNodes(item.children, value)

          if (res && res.length) {
            nodes.push(...res)
          }
        }

        return nodes
      })

      return nodes
    }

    // 格式化输入的值
    const formatInputValue = value => {
      if (props.multiple) {
        const res = (value || '').split(props.separator)

        return res.map(item => findAllNodesValue(props.items, item))
      }

      return findAllNodesValue(props.items, value) || null
    }

    // 格式化输出的值
    const formatOutputValue = value => {
      if (props.multiple) {
        const res = (value || []).map(item => getLastNode(item) || null)

        return res && res.join(props.separator)
      }

      return getLastNode(value) || null
    }

    const text = ref(formatInputValue(props.value))

    const handleChange = val => {
      emit('change', formatOutputValue(val), findAllNodes(props.items, formatOutputValue(val)))
    }

    watch(() => text.value, val => {
      emit('input', formatOutputValue(val))
    })

    watch(() => props.value, val => {
      text.value = formatInputValue(val)
    })

    const formatItems = nodes => {
      if (!nodes || nodes.length <= 0) {
        return null
      }
      const res = []
      for (let i = 0; i < nodes.length; i += 1) {
        const children = nodes[i].children && formatItems(nodes[i].children)
        res.push({
          ...nodes[i],
          children,
        })
      }

      return res
    }

    return {
      text,
      handleChange,
      formatItems,
      setElCascaderMarginTop,
    }
  },
})
</script>

<template>
  <div style="position: relative">
    <Cascader
      v-bind="$attrs"
      v-model="text"
      class="v-cascader"
      :props="{...$attrs, multiple: multiple, value: itemValue, label: itemText}"
      :options="formatItems(items)"
      :collapse-tags="multiple"
      :show-all-levels="showAllLevels"
      :placeholder="placeholder"
      @change="handleChange"
      @visible-change="setElCascaderMarginTop"
    ></Cascader>
  </div>
</template>
<style lang="scss" scoped>
::v-deep .el-cascader .el-input .el-input__inner{
  border: 1px solid rgba(19, 17, 32, 0.32);
}
::v-deep .el-cascader .el-input .el-input__inner::-webkit-input-placeholder{
  color: rgba(0, 0, 0, 0.5)
}
::v-deep .el-cascader .el-input .el-input__inner:focus, ::v-deep .el-cascader .el-input.is-focus .el-input__inner{
  border: 2px solid var(--v-primary-base) !important;
  padding-left: 14px;
}
::v-deep .el-cascader .el-cascader__tags .el-tag{
  max-width: 150px;
}
::v-deep .el-cascader{
  width: 250px;
}
</style>
<style lang="scss">
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
  color: var(--v-primary-base)!important;
}

</style>
