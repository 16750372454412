import { emptyValidator, specialCharValidator } from '@core/utils/validation'
import { modalFail } from '@core/utils/prompt'
import { isObject } from '@core/utils'

// eslint-disable-next-line import/prefer-default-export
export function filterDataProcess(data) {
  const keys = Object.keys(data)
  for (let i = 0; i < keys.length; i += 1) {
    if (specialCharValidator(data[keys[i]])) {
      modalFail('请不要输入特殊字符查询！')

      return false
    }
    // eslint-disable-next-line no-param-reassign
    if (emptyValidator(data[keys[i]])) delete data[keys[i]]
    // eslint-disable-next-line no-param-reassign
    else if (typeof data[keys[i]] === 'string') data[keys[i]] = data[keys[i]].trim()
  }

  return data
}

export const dateGen = dateObj => `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`
export const dateGenMonth = dateObj => `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}`
export const oneDay = 86400000 // 24 * 60 * 60 * 1000
export const twoDay = 172800000 // 2 * 24 * 60 * 60 * 1000
export const oneWeek = 604800000 // 7 * 24 * 60 * 60 * 1000
export const oneMonth = 2592000000 // 30 * 24 * 60 * 60 * 1000
export const halfYear = 15768000000 // 182 * 24 * 60 * 60 * 1000
export const oneYear = 31536000000 // 365 * 24 * 60 * 60 * 1000

export const undefinedResolve = (val, undefinedVal = '') => (typeof val === 'undefined' ? undefinedVal : val)
export const booleanParser = val => (val === null ? null : val === 'true' || val === true || val)
export const intParser = val => (val === null ? null : parseInt(val, 10))
export const floatParser = (val, precision = 2) => (val === null ? null : parseFloat(val).toFixed(precision))
export const MultiInputParser = (val, splitChar = ' ') => (val === null ? null : val.replaceAll('\t', splitChar).split(splitChar).map(s => s.trim()).filter(i => i)
  .join(splitChar))
export const percentageParser = (val, precision = 2) => (val === null ? null : `${(parseFloat(val) * 100).toFixed(precision)} %`)

export const jsonParser = data => {
  if (!data || typeof data !== 'string') return {}
  try {
    return JSON.parse(data)
  } catch (e) {
    console.log(e)

    return {}
  }
}

export const getDataWithKey = (data, keys = 'value') => keys?.split('.').filter(v => v).reduce((acc, cur) => (isObject(acc) && cur in acc ? acc[cur] : acc), data)
export const getData = (data, args, keys = 'value') => (typeof data === 'function' ? data.call(null, args) : getDataWithKey(data, keys))

export const blobParser = (blobData, dateResolver) => new Promise((resolve, reject) => {
  try {
    const reader = new FileReader()
    reader.onload = event => {
      if (dateResolver) resolve(dateResolver(event.target.result))
      resolve(event.target.result)
    }
    reader.readAsText(blobData)
  } catch (e) {
    reject(e)
  }
})
