<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.user.avatar"
            :src="userData.user.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.user.avatar"
              :src="userData.user.avatar"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1 overflow">
            {{ userData.fullName || userData.user.username }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role.join(',') }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Settings -->
      <!-- <v-list-item :to="{ name: 'page-account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>设置</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <!-- <v-list-item :to="{ name: 'page-faq' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>帮助</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>退出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import Swal from 'sweetalert2'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      // 发送退出事件
      useJwt.logout().then(() => {
        // Remove userData from localStorage
        // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        // Reset ability
        vm.$ability.update(initialAbility)

        // Redirect to login page
        // setTimeout(() => router.push({ name: 'auth-login' }), 3000)

        Swal.fire({
          title: '退出成功！',
          confirmButtonText: '确认',
        }).then(() => {
          router.push({ name: 'auth-login' })
        })
      }).catch(error => {
        // DONE: Next Update - Show notification
        console.error('Oops, Unable to logout!')
        console.log('error :>> ', error.response)

        // modalFail(error.response.data.message)
      })
    }

    return {
      logoutUser,
      userData,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
.overflow{
  width: 150px;
  /*1.先强制一行内显示文本*/
  white-space: nowrap;
  /*2.超出的部分隐藏*/
  overflow: hidden;
  /*3.文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
</style>
