<script>
export default {
  name: 'AppTitle',
  computed: {
    title() {
      return this.$route.meta?.pageTitle || ''
    },
    parentAppTitle() {
      return this.$store.state?.app?.parentAppTitle || ''
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<template>
  <div
    v-show="title"
    class="text-h7 font-weight-black title-line"
  >
    <span
      v-if="parentAppTitle"
      class="main-title has-detail"
    >
      <span
        class="iconfont icon-a-xialakuang2x"
        @click="goBack"
      ></span>
      {{ parentAppTitle }}</span>
    <span :class="parentAppTitle ? 'detail-title' : 'main-title'">{{ title }}</span>
  </div>
</template>

<style scoped lang='scss'>
.title-line {
  position: relative;
  display: flex;
  align-items: center;
}
.main-title{
  color: #000000;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-right: 11px;
  .iconfont{
    transform: rotate(90deg);
    display: block;
    margin-right: 4px;
    cursor: pointer;
  }
}
.has-detail::after{
  content: '';
  display: block;
  width: 1px;
  height: 12px;
  margin-left: 11px;
  border: 1px solid rgba(0,0,0,0.1);
}
.detail-title{
  color: rgba(0,0,0,0.5);
  font-size: 14px;
}
</style>
