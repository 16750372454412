const base = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboards/statistics/Statistics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/Index.vue'),
    meta: {
      layout: 'content',
      pageTitle: '首页',
    },
  },
]

export default base
