<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'XwTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    active: {
      type: [String, Number],
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tabsConfig = computed(() => props.tabs.filter(item => item.auth === undefined || item.auth === true))

    const handleChange = idx => {
      emit('change', tabsConfig.value[idx])
    }

    const currActive = computed(() => tabsConfig.value.findIndex(x => x.key === props.active) || 0)

    return {
      currActive,
      tabsConfig,
      handleChange,
    }
  },
})
</script>

<template>
  <v-tabs
    v-if="tabsConfig.length"
    :value="currActive"
    slider-size="0"
    class="tabs-wrap"
    height="66px"
    active-class="tab-acitve"
    style="box-shadow: none !important;"
    @change="handleChange"
  >
    <v-tab
      v-for="item in tabsConfig"
      :key="item.key"
      :tab-value="item.key"
      class="table-title"
      :disabled="isLoading"
    >
      {{ item.name }}{{ item.count ? `(${item.count})` : '' }}
    </v-tab>
    <v-tab-item
      v-for="item in tabsConfig"
      :key="item.key"
    >
      <slot
        v-if="item.slots && item.auth"
        :name="item.slots"
      ></slot>
    </v-tab-item>
  </v-tabs>
</template>
<style lang="scss" scoped>
.tabs-wrap {

  ::v-deep .v-tabs-bar{
    padding: 20px 0 0 0;
    .v-slide-group__content{
      border-bottom: 1px solid #eeeeee;
      margin: 0 20px;
      & > .v-tab{
        background-color: #F6F7F8;
        border-top: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        letter-spacing: normal!important;
        &:last-child{
          border-top-right-radius:10px;
          border-right-width: 1px;
          border-left-width: 0;
          border-top-width: 1px;
          border-bottom-width: 0;
          border-style: solid;
          border-color: #eeeeee;
        }
        &:nth-child(2){
          border-top-left-radius: 10px;
          border-left-width: 1px;
          border-top-width: 1px;
          border-bottom-width: 0;
          border-style: solid;
          border-color: #eeeeee;
        }
      }
      .tab-acitve{
        background: transparent;
      }
    }
  }
  .table-title{
    text-transform: none;
  }
}
</style>
